import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const Home = lazy(() => import("../views/Home"));
const Quality = lazy(() => import("../views/Quality"));
const Pharmacovigilance = lazy(() => import("../views/Pharmacovigilance"));
const Information = lazy(() => import("../views/Information"));

const MainRoutes = (props) => {
  return (
    <Router history={props.history}>
      <Suspense fallback={<div>Chargement...</div>}>
        <Routes>
          <Route exact path={`/`} element={<Home />} />
          <Route exact path={"/quality"} element={<Quality />} />
          <Route
            exact
            path={"/pharmacovigilance"}
            element={<Pharmacovigilance />}
          />
          <Route exact path={`/information`} element={<Information />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default MainRoutes;
