import MainRoutes from "./route/routes";

function App() {
  // Get the id passed as parameter
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id") || window.sessionStorage.getItem("id");
  // Check if no id passed as parameter and saved into the navigator
  sessionStorage.setItem("id", id);
  // Fetch the officine id based of the ID passed in the URL
  return <MainRoutes />;
}

export default App;
