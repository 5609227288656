import React from "react";
// Comment scss import : https://github.com/sass/node-sass/issues/3033
// SASS not working on MacOS M1
// import "./assets/sass/main.scss";
import App from "./App";
import ReactDOM from "react-dom";
import "./assets/index.css";
import { runWithAdal } from "react-adal";
import { authContext } from "./adalConfig";

const DO_NOT_LOGIN = false;

runWithAdal(
  authContext,
  () => {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root")
    );
  },
  DO_NOT_LOGIN
);
